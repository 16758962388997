import { BtnClose } from './Button';
import Image from './Image';
import LinkSafe from './LinkSafe';
import Modal from './Modal';
import { Tit } from './Titles';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface ModalProps {
  isOpen?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 닫힘요청시 콜백
  onRequestClose: () => void;
  maxWidth?: string;
}

export interface DataItem {
  id: string | number;
  title: string;
  type: string;
  first: string | number;
  web: string;
  dec: string;
  pcImage: ImgHTMLAttributes<any>['src'];
  mobileImage: ImgHTMLAttributes<any>['src'];
}

const PopCcontainer = styled.div`
  padding: 0 20px;
`;
const PopupTitle = styled.div`
  border-bottom: 1px solid #1cabe2;
  position: relative;
  padding: 22px 0;
  display: flex;
  justify-content: space-between;
  ${BtnClose} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    &::before,
    &::after {
      background: #1cabe2;
    }
  }
`;

const ImgWrap = styled.div`
  height: 198px;
  margin: 32px 0;
  position: relative;
  overflow: hidden;
`;

const ImgSource = styled(Image)`
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  width: auto;
  height: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
`;

const DlWrap = styled.div`
  border-top: 1px solid #1cabe2;
  padding: 32px 12px;
  padding-bottom: 60px;

  dl {
    margin-bottom: 8px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    dt {
      color: #1cabe2;
      font-weight: 700;
      width: 105px;
      flex: none;
    }
    dd {
      white-space: pre-line;
    }
  }
`;

interface PartnerLayerProps extends ModalProps {
  partnerName: string;
  partnerCate1: string;
  partnerCate2: string;
  partnerYear: string;
  partnerSite: string;
  partnerDesc: string;
  partnerImage: string;
}

const PartnerLayerPop: FC<PartnerLayerProps> = ({
  onRequestClose,
  ...props
}) => (
  <Modal onRequestClose={onRequestClose} maxWidth="640px" {...props}>
    <PopCcontainer>
      <PopupTitle>
        <h2>
          <Tit size="s3" color="sky">
            {props.partnerName}
          </Tit>
        </h2>
        <BtnClose onClick={() => onRequestClose()}>
          <span className="a11y">닫기</span>
        </BtnClose>
      </PopupTitle>
      <ImgWrap>
        <ImgSource pcSrc={props.partnerImage} mobileSrc={props.partnerImage} />
      </ImgWrap>
      <DlWrap>
        <dl>
          <dt>업종</dt>
          <dd>
            {props.partnerCate2 ? (
              <span>
                {props.partnerCate1} &gt; {props.partnerCate2}
              </span>
            ) : (
              <span>{props.partnerCate1}</span>
            )}
          </dd>
        </dl>
        {props.partnerYear && (
          <dl>
            <dt>최초후원</dt>
            <dd>{props.partnerYear}년</dd>
          </dl>
        )}
        {props.partnerSite && (
          <dl>
            <dt>웹사이트</dt>
            <dd>
              <LinkSafe target="_blank" to={props.partnerSite}>
                {props.partnerSite}
              </LinkSafe>
            </dd>
          </dl>
        )}
        {props.partnerDesc && (
          <dl>
            <dt>주요 내용</dt>
            <dd>{props.partnerDesc}</dd>
          </dl>
        )}
      </DlWrap>
    </PopCcontainer>
  </Modal>
);

export default PartnerLayerPop;
