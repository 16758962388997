import {
  AttFileControllerService,
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import {
  CorporateControllerService,
  CsrVo,
  FoBannerControllerService,
  FoBannerVo,
} from '@/__generated__/FrontApi';
import IconOff from '@/assets/img/ico_accordion_off@2x.png';
import UnicefImg1 from '@/assets/img/thumb_width_unicef_img1@2x.jpg';
import UnicefImg2 from '@/assets/img/thumb_width_unicef_img2@2x.jpg';
import Obj1 from '@/assets/img/tmp/ico_contribution_obj1@2x.png';
import Obj2 from '@/assets/img/tmp/ico_contribution_obj2@2x.png';
import Obj3 from '@/assets/img/tmp/ico_contribution_obj3@2x.png';
import Obj4 from '@/assets/img/tmp/ico_contribution_obj4@2x.png';
import Obj5 from '@/assets/img/tmp/ico_contribution_obj5@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import Attachment from '@/components/Attachment';
import Button from '@/components/Button';
import ButtonMoreView from '@/components/ButtonMoreView';
import CampaignCard, { CampaignData } from '@/components/Card/CampaignCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import Row from '@/components/Grid/Row';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputNumber from '@/components/Input/InputNumber';
import InputText from '@/components/Input/InputText';
import InputTextarea from '@/components/Input/InputTextarea';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import PartnerLayerPop from '@/components/PartnerLayerPop';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import Select from '@/components/Select';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { MoreViewLink, Tit } from '@/components/Titles';
import { EVENT_LIST_URI } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { ADDRESS } from '@/page-blocks/involve/ContactCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

const processes = [
  {
    id: 1,
    title: `믿을 수 있는\n파트너`,
    img: Obj1,
    desc: [
      {
        id: 11,
        dec: `70년 이상의 현장 경험과 전문성`,
      },
      {
        id: 12,
        dec: `국제원조기구투명성지수(ATI) 최고등급(2020년)`,
      },
      {
        id: 13,
        dec: `한국가이드스타 크라운 인증(2019-2020년)`,
      },
    ],
  },
  {
    id: 2,
    title: `비즈니스에\n도움이 되는 파트너`,
    img: Obj2,
    desc: [
      {
        id: 21,
        dec: `글로벌 NGO 인지도 1위`,
      },
      {
        id: 22,
        dec: `글로벌 NGO 브랜드 호감도 1위*`,
      },
      {
        id: 23,
        dec: `국내 NGO 중 기부하고 싶은 기관 1위**`,
      },
    ],
    dec: `* 2018 Win/Gallup NGO Survey
    ** 2020 Barometer Survey`,
  },
  {
    id: 3,
    title: `ESG경영에\n기여하는 파트너`,
    img: Obj3,
    desc: [
      {
        id: 31,
        dec: `지속가능개발목표(SDGs) 어린이 관련 아젠다 중 국제적 관리자 역할 수행`,
      },
      {
        id: 32,
        dec: `가장 적극적으로 기여하는 기관`,
      },
    ],
  },
  {
    id: 4,
    title: `글로벌 임팩트\n파트너`,
    img: Obj4,
    desc: [
      {
        id: 41,
        dec: `전 세계 190여개 국가 네트워크`,
      },
      {
        id: 42,
        dec: `각국 정부와 협력을 통한 글로벌 사회공헌 임팩트`,
      },
    ],
  },
  {
    id: 5,
    title: `공유가치\n파트너`,
    img: Obj5,
    desc: [
      {
        id: 51,
        dec: `어린이를 위한 사회적 가치와 기업 강점을 활용해 비즈니스 가치를 창출하는 다양한 파트너십 형태 보유`,
      },
    ],
  },
];

const unicefData = [
  {
    id: 1,
    title: `필란트로피`,
    titleEn: `Philanthropy`,
    img: UnicefImg1,
    desc: `기업 또는 임직원, 단체 구성원이 함께 참여하는 기금 후원 방식`,
    link: `/involve/corporate-and-organization/philanthropie`,
  },
  {
    id: 2,
    title: `파트너십`,
    titleEn: `Partnership`,
    img: UnicefImg2,
    desc: `유니세프와 파트너십 체결을 통해 캠페인 참여, \n마케팅 활동 등 다양한 연계활동 진행`,
    link: `/involve/corporate-and-organization/partnership`,
  },
];

const brochureData = [
  {
    id: 1,
    title: `기업`,
    link: `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYw%3D%3D&dispFileNm=unicef_csr_introduction.pdf&svrFileNm=unicef_csr_introduction.pdf`,
  },
  {
    id: 2,
    title: `기관`,
    link: `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYw%3D%3D&dispFileNm=unicef_major_introduction.pdf&svrFileNm=unicef_major_introduction.pdf`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `기업 문의`,
    costumer: `기업후원팀`,
    tel: `02-721-5121~5`,
    email: `csr@unicef.or.kr`,
  },
  {
    id: 2,
    tit: `기관·단체·재단 문의`,
    costumer: `특별후원팀`,
    tel: `02-721-5151~2`,
    email: `major@unicef.or.kr`,
  },
];

const Title = styled(Tit)`
  margin-bottom: 96px;
  ${breakpoint(`mobile`)} {
    margin-bottom: 72px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
`;

const SwiperCustom = styled(SwiperBasic)`
  .swiper-slide {
    width: 200px;
  }
  &.why-unicef {
    .swiper-button-disabled {
      display: none;
    }
  }
  ${breakpoint(640)} {
    .swiper-slide {
      margin-right: 0;
    }
  }
`;

const ProcessDL = styled.dl`
  dt {
    position: relative;
    text-align: center;

    .img-wrap {
      max-width: 160px;
      width: 100%;
      display: inline-block;

      .img-box {
        width: 100%;
        padding-top: 100%;
      }
    }
  }

  dd {
    padding: 0 10px;

    h3 {
      margin-top: 16px;
      margin-bottom: 8px;
      line-height: 1.6;
      letter-spacing: -1.2px;
      text-align: center;
    }

    ul {
      & > li {
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        & > * {
          display: inline-block;
        }

        span + span {
          display: block;
        }

        .link {
          line-height: 28px;
          padding: 0 10px;
          background: #1cabe2;
          color: #fff;
          margin-left: 8px;
        }
      }
      &.blit > li {
        padding-left: 10px;
        text-align: left;
        font-size: 14px;

        &::before {
          content: '•';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .dec {
      display: inline-block;
      color: #b7b8ba;
      font-size: 14px;
      text-align: left;
    }
  }

  ${breakpoint(`tablet`)} {
    dt {
      .img-wrap {
        max-width: 80px;
      }
    }
    dd {
      ul.blit > li {
        font-size: 12px;
      }
    }
  }
`;

const SectionCampaign = styled(Section)`
  background: #e3f0f6;
`;

const SectionUnicef = styled(Section)`
  article {
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  h4 {
    margin-bottom: 32px;
  }
`;
const UnicefWrap = styled.article`
  .img-box {
    width: 100%;
    padding-top: 100%;
  }
  a {
    cursor: pointer;
  }
`;

const Flex = styled.div`
  display: flex;
  margin: 0 -16px;

  .col {
    width: 50%;
    padding: 0 16px;

    h4 {
      padding: 32px 0;
      border-bottom: 1px solid #2d2926;

      ${Tit} {
        margin-bottom: 10px;
        display: block;
      }
    }

    p {
      line-height: 2;
      min-height: 62px;
    }

    ${ButtonMoreView} {
      margin-top: 98px;
      width: 256px;
    }
  }

  ${breakpoint(`tablet`)} {
    flex-wrap: wrap;

    .col {
      width: 100%;
      margin-bottom: 72px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      h4 {
        padding: 24px 0;
      }
      p {
        min-height: 0;
      }
      ${ButtonMoreView} {
        margin-top: 52px;
        max-width: 148px;
      }
    }
  }
`;

const BrochureWrap = styled.article`
  ${Button} {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    ${breakpoint(`mobile`)} {
      padding: 15px 24px;
    }
  }

  ${breakpoint(`tablet`)} {
    .col {
      margin-bottom: 16px;
    }
  }
`;

const DonationNews = styled.article`
  .swiper-slide {
    width: 100% !important;
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }

    .img-box {
      padding-top: 19.64%;
    }

    ${breakpoint(`mobile`)} {
      .swiper-slide {
        width: 100%;
      }
    }
  }
`;

const SectionPartner = styled(Section)`
  .SwiperCustom {
    padding: 0;
  }
  .link-item {
    display: block;
    padding: 8px;
    position: relative;
    width: 100%;
    height: auto;
    border: 0;
    background: none !important;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: 8px solid transparent;
    }

    &:hover:before {
      border-color: #1cabe2;
    }
  }
  .img-box {
    padding-top: 100%;
    width: 100%;
    background-size: 100%;
  }
`;

const CardLayout = styled.div``;
const CardImage = styled.div``;

const ContactWrap = styled.div`
  padding: 240px 0 120px 0;

  ${breakpoint(`tablet`)} {
    padding: 120px 0 60px 0;
  }
`;

const AccordionContainer = styled(AccordionComponent)`
  header {
    &::after {
      right: 24px;
      background-image: url(${IconOff});
      background-color: #fff;
      border-radius: 100%;
      transform: rotate(0);
      top: 50%;
      margin-top: -16px;
      z-index: 0;
    }
    ${breakpoint(640)} {
      right: 0;
    }
  }
  .accordion-header {
    background: #1cabe2;
    strong {
      display: block;
      position: relative;
      z-index: 1;
    }
  }
  .is-active {
    header {
      &::after {
        background-image: url(${IconOff});
        background-color: #fff;
        border-radius: 100%;
        transform: rotate(180deg);
      }
    }
  }
  .accordion-header {
    position: relative;
    padding: 0 20px;

    ${Tit} {
      line-height: 80px;
    }
  }
  li {
    border-bottom: 0;
    &::after {
      display: none;
    }
  }
  .contact-form {
    background: #f8f9fa;
    padding: 30px 20px 40px 20px;
  }
`;

const FormContainer = styled.div`
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  ${LabelText} {
    color: #2d2926;
  }
  ${FormGroup} {
    width: 50%;
    padding: 0 16px;
    margin-bottom: 24px;

    &.w50 {
      width: 50% !important;
    }

    &.full {
      width: 100%;
    }

    &.file-group {
      max-width: 544px;
    }

    .tip {
      color: #828385;
      font-size: 14px;
      margin-top: 10px;
      ${breakpoint(640)} {
        font-size: 12px;
      }
    }

    .chk-label {
      position: relative;
      padding-left: 30px;
    }
  }

  ${breakpoint(`tablet`)} {
    margin: 0 -12px;

    ${FormGroup} {
      width: 100%;
      padding: 0 12px;
    }
  }
`;

const BtnWrap = styled.div`
  margin: 0 auto;
  margin-top: 60px;
  width: 100%;
  max-width: 352px;
`;
const NavigationButton = styled.div`
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  position: relative;

  ${breakpoint(640)} {
    font-size: 16px;
    padding: 20px 40px 20px 0;
  }
`;
const AccordionBody = styled.div`
  padding: 24px;
  font-size: 16px;
  color: #2d2926;
  white-space: pre-line;
  ${breakpoint(`mobile`)} {
    font-size: 14px;
    padding: 16px 0;
  }
  // blut css 보정
  ul,
  ol,
  li {
    list-style: inherit;
    list-style-position: inside;
    list-style-type: inherit;
  }
  ol {
    list-style-type: demical;
  }
  ul {
    list-style-type: disc;
  }
  a {
    color: #1cabe2;
  }
`;

const SectionInquiry = styled(Section)`
  // anchor 스크롤 이슈 해결용 padding
  padding-top: 120px;

  ${NavigationButton} {
    padding: 0 20px !important;
  }
  .contactus {
    .is-active {
      border-bottom: none;
    }
  }
  ${breakpoint(`mobile`)} {
    padding-top: 60px;
  }
`;
const SectionFaq = styled(Section)`
  padding-bottom: 0;
`;

export interface FaqData {
  id: string | number;
  title?: string;
  contents?: string;
}
export interface PartnerData {
  id: string | number;
  indexNumber?: string | number;
  title?: string;
  category1?: string;
  category2?: string;
  url?: string;
  year?: string | number;
  desc?: string;
  imgPath?: string;
}
type ADDRESS_TYPE = typeof ADDRESS;
interface ContactData {
  socialType: string; // 구분(기업 or 기관/재단/단체)
  socialCompanyName: string; // 기업/단체명
  socialAddress1: keyof ADDRESS_TYPE; // 소재지(시/도)
  socialAddress2: string; // 소재지(시/군/구)
  socialManagerName: string; // 담당자명
  socialDepartment?: string; // 부서명
  socialContact: string; // 연락처(&lsquo;-&rsquo;제외)
  socialEmail: string; // 이메일 주소
  socialContents: string; // 문의내용
  socialIsAgreeYn: boolean; // 기업기관후원안내 동의여부 (Y/N)
}
const Contribution: React.FC<PageProps> = ({ location }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [faqLists, setFaqLists] = useState<FaqData[]>([]);
  const [partners, setPartners] = useState<PartnerData[]>([]);
  const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
  const [donationNews, setDonationNews] = useState<CampaignData[]>([]);

  const loadFaqLists = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['260'],
        optData1: '1',
        optData2: '103',
        pagePerCount: 6,
        statusCode: '1',
      });
      const organizationFaq = data as any;
      setFaqLists(
        organizationFaq.map((faq: BoardVo) => ({
          id: faq.boardIndexNumber,
          title: faq.subject,
          contents: faq.contents,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadPartners = useCallback(async () => {
    try {
      const {
        data: organizationLogo,
      } = await CorporateControllerService.getCsrLogoListUsingGet();
      setPartners(
        organizationLogo.map((logo: CsrVo) => ({
          id: logo.indexNumber,
          indexNumber: logo.indexNumber,
          title: logo.csrName,
          category1: logo.csrCate1,
          category2: logo.csrCate2,
          url: logo.csrSite,
          year: logo.csrYear,
          desc: logo.csrDesc,
          imgPath: logo.logoAttGrpNo,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadCampaigns = useCallback(async () => {
    try {
      const {
        data: articles,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['200'],
        optData2: '2',
        outDateDispYn: 'Y',
      });
      setCampaigns(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: article.referUrl || EVENT_LIST_URI + article.boardIndexNumber,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadDonationNews = useCallback(async () => {
    try {
      const {
        data: partnershipBanners,
      } = await FoBannerControllerService.selectBannerListUsingGet({
        kind: '4001',
      });
      setDonationNews(
        partnershipBanners.map((banner: FoBannerVo) => ({
          id: banner.indexNumber,
          pcImage: banner.imgAttGrpNoPc,
          mobileImage: banner.imgAttGrpNoMobile,
          link: banner.targetUrl,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadFaqLists();
    loadPartners();
    loadCampaigns();
    loadDonationNews();
  }, [loadCampaigns, loadDonationNews, loadFaqLists, loadPartners]);

  const [partnerName, setPartnerName] = useState(``); // 업종1
  const [partnerCate1, serPartnerCate1] = useState(``); // 업종1
  const [partnerCate2, serPartnerCate2] = useState(``); // 업종2
  const [partnerYear, setPartnerYear] = useState(``); // 최초후원
  const [partnerSite, setPartnerSite] = useState(``); // 웹사이트
  const [partnerDesc, setPartnerDesc] = useState(``); // 주요 내용
  const [partnerImage, setPartnerImage] = useState(``); // 로고 이미지

  const popupStore = usePopupStore();
  const [addressVal, setAddressVal] = useState(``);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm<ContactData>({
    defaultValues: {
      socialType: ``,
      socialCompanyName: ``,
      socialAddress1: undefined,
      socialAddress2: undefined,
      socialManagerName: ``,
      socialDepartment: ``,
      socialContact: ``,
      socialEmail: ``,
      socialContents: ``,
      socialIsAgreeYn: false,
    },
  });
  const socialAddress = watch(`socialAddress1`);
  // 첨부파일
  const [files, setFiles] = useState<File[]>([]);

  const onSubmit: SubmitHandler<ContactData> = useCallback(
    async ({
      socialType,
      socialCompanyName,
      socialAddress1,
      socialAddress2,
      socialManagerName,
      socialDepartment,
      socialContact,
      socialEmail,
      socialContents,
      socialIsAgreeYn,
      ...formData
    }) => {
      try {
        let attGrpNo: any;
        // 파일이 있다면
        if (files.length > 0) {
          const result = await AttFileControllerService.uploadUsingPost({
            file: files,
          });
          attGrpNo = result.data.attGrpNo;
        }

        const {
          resultCode,
          resultMessage,
        } = await CorporateControllerService.postQnaInsertUsingPost({
          ...formData,
          type: socialType,
          companyName: socialCompanyName,
          address1: socialAddress1,
          address2: socialAddress2,
          managerName: socialManagerName,
          department: socialDepartment,
          contact: socialContact,
          email: socialEmail,
          contents: socialContents,
          isAgreeYn: socialIsAgreeYn ? `Y` : `N`,
          attGrpNo,
        });
        popupStore.show(resultMessage);
        // 성공후 폼 리셋
        reset();
        setFiles([]);
      } catch (e) {
        popupStore.show(`문의 등록에 실패했습니다. 다시 시도해주세요.`);
        // console.error(e);
      }
    },
    [files, popupStore, reset],
  );

  return (
    <LayoutWithTitle
      location={location}
      title="기업·기관 사회공헌"
      description="for every child, partnerships"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">
                  왜 유니세프와 함께해야 하나요?
                </PageComponent>
              </Tit>
            </h2>
          </SectionHeader>
          <SwiperCustom
            className="why-unicef"
            css={`
              padding: 0;
            `}
          >
            <Swiper
              slidesPerView="auto"
              navigation
              scrollbar={{ draggable: true }}
            >
              {processes.map((row) => (
                <SwiperSlide key={row.id}>
                  <ProcessDL>
                    <dt>
                      <div className="img-wrap">
                        <div
                          className="img-box"
                          css={`
                            background-image: url(${row.img});
                          `}
                        />
                      </div>
                    </dt>
                    <dd>
                      <h3>
                        <Tit size="s4" color="sky">
                          {row.title.split(`\n`).map((line) => (
                            <span key={line}>
                              {line}
                              <br />
                            </span>
                          ))}
                        </Tit>
                      </h3>
                      <ul className="blit">
                        {row.desc.map((row2) => (
                          <li key={row2.id}>
                            {row2.dec
                              ? row2.dec.split(`\n`).map((line) => (
                                  <span key={line}>
                                    {line}
                                    <br />
                                  </span>
                                ))
                              : ``}
                          </li>
                        ))}
                      </ul>
                      {row.dec ? <p className="dec">{row.dec}</p> : ``}
                    </dd>
                  </ProcessDL>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperCustom>
        </Container>
      </Section>

      <SectionUnicef className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                전 세계 어린이를 위해 <br />
                유니세프와 함께하는 방법
              </Tit>
            </h2>
          </SectionHeader>

          <UnicefWrap>
            <Flex>
              {unicefData.map((row) => (
                <div className="col" key={row.id}>
                  <div
                    className="img-box"
                    css={`
                      background-image: url(${row.img});
                    `}
                  />
                  <h4>
                    <Tit size="s2">{row.title}</Tit>
                    <Tit size="s2">{row.titleEn}</Tit>
                  </h4>
                  <p>
                    {row.desc.split(`\n`).map((line) => (
                      <span key={line}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                  <ButtonMoreView
                    width="256"
                    mode="sky"
                    onClick={() => navigate(row.link)}
                  >
                    자세히 보기
                  </ButtonMoreView>
                </div>
              ))}
            </Flex>
          </UnicefWrap>

          <BrochureWrap>
            <h4>
              <Tit size="s2">브로셔 다운로드</Tit>
            </h4>
            <Flex>
              {brochureData.map((row) => (
                <div className="col" key={row.id}>
                  <Button
                    ico="down"
                    outline
                    full
                    onClick={() => window.open(row.link)}
                  >
                    <span className="ico">{row.title}</span>
                  </Button>
                </div>
              ))}
            </Flex>
          </BrochureWrap>

          {donationNews.length === 0 ? (
            ``
          ) : (
            <DonationNews>
              <h4>
                <Tit size="s2">후원 소식</Tit>
              </h4>
              <CampaignCard
                campaigns={donationNews}
                img
                autoplay
                overflowHidden
              />
            </DonationNews>
          )}
        </Container>
      </SectionUnicef>
      <SectionCampaign className="by-sub-main-layout" id="campaign">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">착한 캠페인</Tit>
              </h2>
              <LinkSafe
                to="/involve/event/?optData2=2"
                className="more"
                target="_blank"
              >
                <span>더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {campaigns.length > 0 && (
            <CampaignCard campaigns={campaigns} hash="campaign" />
          )}
        </Container>
      </SectionCampaign>
      <SectionPartner className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">유니세프와 함께하는 기업·기관</Tit>
            </h2>
          </SectionHeader>

          {partners.length > 0 && (
            <SwiperCustom className="SwiperCustom" overflowHidden>
              <Swiper
                navigation
                scrollbar={{ draggable: true }}
                slidesPerView={4}
                autoplay
                breakpoints={{
                  769: {
                    slidesPerView: 4,
                  },
                  320: {
                    slidesPerView: 2,
                  },
                }}
              >
                {partners.map((partner) => (
                  <SwiperSlide key={partner.id}>
                    <Button
                      onClick={() => {
                        setIsModalOpen(true);
                        setPartnerName(partner.title);
                        serPartnerCate1(partner.category1);
                        serPartnerCate2(partner.category2);
                        setPartnerSite(partner.url);
                        setPartnerYear(partner.year);
                        setPartnerDesc(partner.desc);
                        setPartnerImage(partner.imgPath);
                      }}
                      className="link-item"
                    >
                      <CardLayout>
                        <CardImage>
                          <div
                            className="img-box"
                            css={`
                              background-image: url(${partner.imgPath});
                            `}
                          >
                            <span className="a11y">{partner.title}</span>
                          </div>
                        </CardImage>
                      </CardLayout>
                    </Button>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperCustom>
          )}
          <PartnerLayerPop
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            partnerName={partnerName}
            partnerCate1={partnerCate1}
            partnerCate2={partnerCate2}
            partnerYear={partnerYear}
            partnerSite={partnerSite}
            partnerDesc={partnerDesc}
            partnerImage={partnerImage}
          />
        </Container>
      </SectionPartner>
      <SectionFaq className="by-sub-main-layout">
        <Container>
          <TitleContainer>
            <Title size="s1">자주 묻는 질문</Title>
            <MoreViewLink to="/faq?category=1&optData2=103#categorySection">
              <span>더 보기</span>
            </MoreViewLink>
          </TitleContainer>
          {faqLists.length > 0 && (
            <AccordionComponent initialKey={faqLists[0].id}>
              {faqLists.map((faq) => (
                <AccordionItem
                  key={faq.id}
                  toggleKey={faq.id}
                  renderToggle={(onClick) => (
                    <NavigationButton
                      onClick={onClick}
                      className="accordion-opener"
                    >
                      {faq.title}
                    </NavigationButton>
                  )}
                >
                  <AccordionBody
                    dangerouslySetInnerHTML={{ __html: faq.contents || `` }}
                  />
                </AccordionItem>
              ))}
            </AccordionComponent>
          )}
          <ContactWrap>
            <h3 className="a11y">문의</h3>

            <ContactUs itemData={contactData} />
          </ContactWrap>
        </Container>
      </SectionFaq>
      <SectionInquiry id="sectionInquiry">
        <Container
          css={`
            ${breakpoint(`mobile`)} {
              padding-left: 0;
              padding-right: 0;
            }
          `}
        >
          <AccordionContainer
            initialKey={location.hash === `#sectionInquiry` ? `form` : ``}
            className="contactus"
          >
            <AccordionItem
              key="form"
              toggleKey="form"
              renderToggle={(onClick) => (
                <NavigationButton
                  onClick={onClick}
                  className="accordion-header"
                >
                  <Tit size="s2" color="white">
                    온라인 문의하기
                  </Tit>
                </NavigationButton>
              )}
            >
              <div className="contact-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormContainer>
                    <FormGroup className="FormGroup">
                      <Row>
                        <LabelText require>구분</LabelText>
                      </Row>
                      <Row>
                        <Select
                          name="socialType"
                          ref={register({
                            required: {
                              value: true,
                              message: `구분을 선택해주세요.`,
                            },
                          })}
                        >
                          <option value="" disabled>
                            구분 선택
                          </option>
                          <option value="기업">기업</option>
                          <option value="기관/재단/단체">기관/재단/단체</option>
                        </Select>
                      </Row>
                      {errors.socialType && (
                        <ErrorMessage>{errors.socialType.message}</ErrorMessage>
                      )}
                    </FormGroup>

                    <FormGroup className="FormGroup">
                      <Row>
                        <LabelText require>기업·단체명</LabelText>
                      </Row>
                      <Row>
                        <InputText
                          placeholder="기업·단체명"
                          name="socialCompanyName"
                          ref={register({
                            required: {
                              value: true,
                              message: `기업·단체명을 입력해주세요.`,
                            },
                            maxLength: {
                              value: 50,
                              message: `50자 이내로 입력해주세요.`,
                            },
                          })}
                        />
                      </Row>
                      {errors.socialCompanyName && (
                        <ErrorMessage>
                          {errors.socialCompanyName.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>

                    <FormGroup className="FormGroup w50">
                      <Row>
                        <LabelText require>소재지</LabelText>
                      </Row>
                      <Row>
                        <Select
                          name="socialAddress1"
                          ref={register({
                            required: {
                              value: true,
                              message: `시/도를 선택해주세요.`,
                            },
                          })}
                        >
                          <option value="시/도 선택">시/도 선택</option>
                          {Object.keys(ADDRESS || {}).map((key) => (
                            <option key={key} value={key}>
                              {key}
                            </option>
                          ))}
                        </Select>
                      </Row>
                      {errors.socialAddress1 && (
                        <ErrorMessage>
                          {errors.socialAddress1.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>
                    <FormGroup className="FormGroup w50">
                      <Row>&nbsp;</Row>
                      <Row>
                        <Select
                          name="socialAddress2"
                          ref={register({
                            required: {
                              value: true,
                              message: `시/군/구를 선택해주세요.`,
                            },
                          })}
                        >
                          <option value="시/군/구 선택">시/군/구 선택</option>
                          {ADDRESS[socialAddress]?.map((address) => (
                            <option key={address} value={address}>
                              {address}
                            </option>
                          ))}
                        </Select>
                      </Row>
                      {errors.socialAddress2 && (
                        <ErrorMessage>
                          {errors.socialAddress2.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>

                    <FormGroup className="FormGroup">
                      <Row>
                        <LabelText require>담당자명</LabelText>
                      </Row>
                      <Row>
                        <InputText
                          placeholder="담당자명 입력"
                          name="socialManagerName"
                          ref={register({
                            required: {
                              value: true,
                              message: `담당자명을 입력해주세요.`,
                            },
                            maxLength: {
                              value: 50,
                              message: `50자 이내로 입력해주세요.`,
                            },
                          })}
                        />
                      </Row>
                      {errors.socialManagerName && (
                        <ErrorMessage>
                          {errors.socialManagerName.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>
                    <FormGroup className="FormGroup">
                      <Row>
                        <LabelText>부서명</LabelText>
                      </Row>
                      <Row>
                        <InputText
                          placeholder="부서명 입력(선택)"
                          name="socialDepartment"
                          ref={register({
                            maxLength: {
                              value: 50,
                              message: `50자 이내로 입력해주세요.`,
                            },
                          })}
                        />
                      </Row>
                      {errors.socialDepartment && (
                        <ErrorMessage>
                          {errors.socialDepartment.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>
                    <FormGroup className="FormGroup">
                      <Row>
                        <LabelText require>연락처</LabelText>
                      </Row>
                      <Row>
                        <Controller
                          control={control}
                          name="socialContact"
                          rules={{
                            required: {
                              value: true,
                              message: `연락처를 입력해주세요.`,
                            },
                            pattern: {
                              value: mobileRegex,
                              message: `올바른 연락처 형식이 아닙니다.`,
                            },
                            validate: {
                              isValidRange: (value) =>
                                (value.substr(0, 3) === `010` &&
                                  value.length === 11) ||
                                (value.substr(0, 3) !== `010` &&
                                  value.length === 10) ||
                                `휴대폰번호 자릿수를 확인해주세요`,
                            },
                          }}
                          as={
                            <InputNumber
                              format="###########"
                              placeholder="연락처 입력 ( &lsquo;-&rsquo; 제외 )"
                            />
                          }
                        />
                      </Row>
                      {errors.socialContact && (
                        <ErrorMessage>
                          {errors.socialContact.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>
                    <FormGroup className="FormGroup">
                      <Row>
                        <LabelText require>이메일</LabelText>
                      </Row>
                      <Row>
                        <InputText
                          name="socialEmail"
                          ref={register({
                            required: {
                              value: true,
                              message: `이메일을 입력해주세요.`,
                            },
                            pattern: {
                              value: emailRegex,
                              message: `올바른 이메일 형식이 아닙니다.`,
                            },
                            maxLength: {
                              value: 100,
                              message: `100자 이내로 입력해주세요.`,
                            },
                          })}
                          placeholder="이메일주소 입력"
                        />
                      </Row>
                      {errors.socialEmail && (
                        <ErrorMessage>
                          {errors.socialEmail.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>
                    <FormGroup className="FormGroup full">
                      <Row>
                        <LabelText require>문의 내용</LabelText>
                      </Row>
                      <Row>
                        <InputTextarea
                          placeholder="문의 내용을 입력하세요.&#13;&#10;후원 규모를 대략적으로 적어주시면 빠른 답변에 도움이 됩니다."
                          name="socialContents"
                          ref={register({
                            required: {
                              value: true,
                              message: `문의 내용을 입력해주세요.`,
                            },
                            maxLength: {
                              value: 1200,
                              message: `1200자 이내로 입력해주세요.`,
                            },
                          })}
                        />
                      </Row>
                      {errors.socialContents && (
                        <ErrorMessage>
                          {errors.socialContents.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>

                    <FormGroup className="file-group full">
                      <Attachment
                        accept="application/pdf"
                        multiple
                        files={files}
                        onFilesChange={setFiles}
                      />
                      <p className="tip">
                        ※첨부파일 (최대 5개의 PDF 형식만 가능)
                      </p>
                    </FormGroup>
                    <FormGroup className="chk-group full">
                      <InputCheckbox
                        label="예, 저는 기업·기관 후원 안내를 받고 싶습니다. 그리고 안내를 원치 않을 시, 언제든지 수신 거부 할 수 있음을 잘 알고 있습니다."
                        name="socialIsAgreeYn"
                        ref={register({ required: true })}
                      />
                      {errors.socialIsAgreeYn && (
                        <ErrorMessage>약관 동의에 체크해주세요.</ErrorMessage>
                      )}
                    </FormGroup>
                  </FormContainer>
                  <BtnWrap>
                    <Button full type="submit">
                      문의하기
                    </Button>
                  </BtnWrap>
                </form>
              </div>
            </AccordionItem>
          </AccordionContainer>
        </Container>
      </SectionInquiry>
    </LayoutWithTitle>
  );
};

export default Contribution;
